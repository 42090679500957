import styled from 'styled-components'

export const PostsWrapper = styled.div`
  display: grid;
  grid-template-columns: 50% 50%;
`
export const Title = styled.h1``

export const PostsContainer = styled.section`
  margin-top: 3rem;
`
